import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { NavigationEnd, Router } from '@angular/router';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { LayoutService } from '../../../@core/utils';
import { ProfileComponent } from '../../../E-justice/dialogs/profile/profile.component';
import { FrapperService } from "../../../E-justice/services/frapper/frapper.service";
import {ViewListJuridictionsComponent} from "../../../E-justice/dialogs/view-list-juridictions/view-list-juridictions.component";
import { MyCONST } from '../../../E-justice/CONST/myConst';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  profil: any;
  my_profil: any;
  is_public: boolean = false;
  myConst = MyCONST;
  menu : any = []
  active_menu= false
  activeRoute: string = '';



  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private frapper: FrapperService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private modalService: BsModalService,
    private ngxService: NgxUiLoaderService,

    private router: Router) {

      this.menu = JSON.parse(localStorage.getItem('menu') +'')
      console.log('the menu===> list-group-item/ "groupTitle"', this.menu);


      // for active route
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.activeRoute = this.router.url;
      });

  }

  openModalProfile() {
    const config: ModalOptions = {
      initialState: {
        parentModal: null // Initialiser parentModal à null
      }
    }

    // Ouvrir le modal
    const modalRef = this.modalService.show(ProfileComponent, Object.assign({}, config, { class: 'modal-xl' }));

    // Mettre à jour l'option initialState avec le modalRef
    // modalRef.content.parentModal = modalRef;
  }

  onChangeMenu(route: any ){
    console.log('route selected=> ',route);

    this.router.navigate([route])


  }





  openModalJuridiction() {
    const config: ModalOptions = {
      initialState: {
        parentModal: null // Initialiser parentModal à null
      }
    }

    // Ouvrir le modal
    const modalRef = this.modalService.show(ViewListJuridictionsComponent, Object.assign({}, config, { class: 'modal-xl' }));

    // Mettre à jour l'option initialState avec le modalRef
    // modalRef.content.parentModal = modalRef;
  }

  ngOnInit() {
    this.is_public = MyCONST.IS_PUBLIC === 1? true : false

    this.profil = localStorage.getItem('me_username');
    this.my_profil = localStorage.getItem('profil');

    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = users.nick);

    // const username = this.userService.getConnectedUser();
    // this.user = username;

  }

  // openMenu(route: String)
  async refresh(){
    this.ngxService.start();
    try {
     await this.ngOnInit();



      this.ngxService.stop();


    } catch (error) {
      this.ngxService.stop();
    }

  }

  async logout(){
    await this.frapper.logOut();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  handleAction(action: string) {
    switch (action) {
      case 'Log out':
        this.frapper.logOut();
        break;

      case 'Profile':
        console.log('Profil');
        this.openModalProfile();
        // Votre logique pour afficher la famille de l'utilisateur ici
        // this.opentabsfamille(this.selectedUser);
        break;

      // Ajoutez d'autres cas selon vos besoins

      default:
      // Gérez le cas par défaut si aucune correspondance n'est trouvée
      // console.warn('Action non gérée:', action);
    }
  }

  navigateHome() {
    if (!this.is_public) {
      this.router.navigate(['E-justice/home'])

    }
  }





}
