import { Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import {FormGroup, FormControl, Validators, FormBuilder, NgForm} from '@angular/forms';
import * as $ from 'jquery';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SuccessDialogComponent } from '../../widgets/successDialog/success-dialog.component';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { AddPieceComponent } from '../add-piece/add-piece.component';
import {UsercourrierService} from "../../services/frapper/usercourrier.service";
import {DossierService} from "../../services/frapper/dossier.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {AlertesService} from "../../services/public/alertes.service";
import {CustomService} from "../../services/frapper/custom.service";
import {error} from "@angular/compiler-cli/src/transformers/util";
import {MyCONST} from "../../CONST/myConst";
import {VisualizePieceComponent} from "../visualize-piece/visualize-piece.component";
import { AddAssociationPrevenuComponent } from '../add-association-prevenu/add-association-prevenu.component';
import { DetailsPrevenuComponent } from '../details-prevenu/details-prevenu.component';


@Component({
  selector: 'ngx-add-dossier',
  templateUrl: './add-dossier.component.html',
  styleUrls: ['./add-dossier.component.scss']
})
export class AddDossierComponent {
  @Input() parentModal: BsModalRef;

  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data: any;
  type:any;
  modalRef!: BsModalRef;
  is_multiple_prevenu: number ;

  my_tribunal:any = null

  classifications:any =[];
  natures:any =[];
  identification_type:any =[];
  InternalPieces:any =[]
  prevenusList: any[] = [];
  CAN_SEE_REMARQUE_PROCUREUR:boolean=false
  CAN_NOT_CHANGE_REMARQUE_PROCUREUR:boolean=true
  profil
  profil_initial_step=[
    {
      "role": "AGENT DU COURRIER",
      "step_dossier":'Procureur',
      "step_courrier":'Procureur'
    },
    {
      "role": "AGENT DU COURRIER CIVIL",
      "step_dossier":'President du tribunal',
      "step_courrier":'President du tribunal'
    },
    {
      "role": "AGENT DU COURRIER FAMILIAL",
      "step_dossier":'President du tribunal',
      "step_courrier":'President du tribunal'
    },
    {
      "role": "AGENT DU COURRIER SOCIAL",
      "step_dossier":'President du tribunal',
      "step_courrier":'President du tribunal'
    },
    {
      "role": "AGENT DU COURRIER PRESIDENT",
      "step_dossier":'President du tribunal',
      "step_courrier":'President du tribunal'
    },
    {
      "role": "AGENT DU COURRIER GREF",
      "step_dossier":'Greffier',
      "step_courrier":'Greffier'
    },
    {
      "role": "PROCUREUR",
      "step_dossier":'Procureur',
      "step_courrier":'Procureur'
    },
    {
      "role": "SUB PROCUREUR",
      "step_dossier":'Substitut du procureur',
      "step_courrier":'Substitut du procureur'
    },
    {
      "role": "GREFFIER",
      "step_dossier":'Greffier',
      "step_courrier":'Greffier'
    },
    {
      "role": "GREFFIER EN CHEF",
      "step_dossier":'Greffier',
      "step_courrier":'Greffier'
    },
    {
      "role": "JUGE D'INSTRUCTION",
      "step_dossier":'Juge d\'instruction',
      "step_courrier":'Juge d\'instruction'
    },
    {
      "role": "JUGE",
      "step_dossier":'Juge',
      "step_courrier":'Juge'
    },
    {
      "role": "PRESIDENT DU TRIBUNAL",

      "step_dossier":'President du tribunal',
      "step_courrier":'President du tribunal'
    },
    {
      "role": "AGENT ENROLEMENT",
      "step_dossier":'Greffier',
      "step_courrier":'Greffier'
    },
    {
      "role": "AGENT ENROLEMENT PENAL",
      "step_dossier":'Procureur',
      "step_courrier":'Procureur'
    }
  ]


  constructor(private modalService: BsModalService,
              public options: ModalOptions,
              private formBuilder: FormBuilder,
              private dossierService:DossierService,
              private customservice:CustomService,
              private ngxService: NgxUiLoaderService,
              private alerte:AlertesService,
              private zone: NgZone,
              public bsModalRef: BsModalRef,
              private userCourrierService:UsercourrierService,
              private penalService: PenalCourrierService) {
    this.profil = localStorage.getItem('profil');
    this.getInitialStepOfDossier()
    this.data = options.initialState
    try {
      this.InternalPieces = this.data.data.pieces_jointe_au_courrier
    }catch (e) {
      //this.InternalPieces =[]
    }


    console.log(this.InternalPieces)
    this.type = options.initialState.typeCourrier;

  }

  closeWithMessage(message: string) {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.emit(message); 
  }

  openModalSuccessDialog(message: string) {
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm'}));
  }

  doAction(){
    if(this.profil == 'PROCUREUR' || this.profil =='SUB PROCUREUR'){
      this.CAN_SEE_REMARQUE_PROCUREUR = true
      if(this.profil == 'PROCUREUR'){
        this.CAN_NOT_CHANGE_REMARQUE_PROCUREUR=false
      }
    }
  }

  openModalAddPiece() {

    const config: ModalOptions = {
      initialState: {
        // message: message
      }
    };
    // this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(AddPieceComponent, Object.assign({}, config, {class:'modal-lg'}));
  }

  hidde() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  selectTypePrevenu(event: any){

    const isChecked = event.target.checked;
    if (isChecked) {
      this.is_multiple_prevenu = 1;
    }else{
      this.is_multiple_prevenu = 0;
    }
    console.log('is_multiple_prevenu==>',this.is_multiple_prevenu);


}

  initializeStepper() {
    console.log('innnnn ssteppp 31  '+this.type );
    var stepFirst = this.type;

    $(document).ready(function(){
      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;
      var current = 1;
      var steps = $("fieldset").length;


      setProgressBar(current);



      if (stepFirst == 'step3') {
        console.log('in step333 === '+ stepFirst);


        $("fieldset.step1").hide();
        $("fieldset#step1").addClass("active");
        $("fieldset.step2").hide();
        $("#step2").addClass("active");
          // Afficher le fieldset de classe step3
          $("fieldset.step3").show();
        // $("fieldset").eq(2).show();
        current = 4;
        setProgressBar(current++);

        // Ajouter la classe "active" au deuxième élément de la barre de progression
        $("#progressbar li").eq(2).addClass("active");
        console.log("Au moins un élément fieldset a la classe 'cache'");
      }




      // Fonction pour définir la barre de progression
      function setProgressBar(curStep){
          var percent = (100 / steps) * curStep;
          percent = percent
          $(".progress-bar").css("width",percent+"%");
      }

      // Logique pour passer à l'étape suivante
      $(".next").click(function(){
          current_fs = $(this).parent();
          next_fs = $(this).parent().next();

          // Ajouter la classe "active" à l'étape suivante de la barre de progression
          $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

          // Afficher le fieldset suivant avec une animation
          next_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  next_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(++current);
      });

      // Logique pour revenir à l'étape précédente
      $(".previous").click(function(){
          current_fs = $(this).parent();
          previous_fs = $(this).parent().prev();

          // Retirer la classe "active" de l'étape actuelle de la barre de progression
          $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

          // Afficher le fieldset précédent avec une animation
          previous_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  previous_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(--current);
      });
  });
  }


  getInitialStepOfDossier(){
    let init_step =''
    let profil_step = this.profil_initial_step.filter(element => element.role?.toUpperCase() == this.profil);
    console.log('profil_step ==> ',profil_step)
    if(profil_step.length > 0){
      init_step = profil_step[0].step_dossier
    }
    console.log('init_step ==> ',init_step)
    return init_step
  }

  onClassificationChange(event: any) {
    let chaine = event.target.value;
    console.log('this.imageFile ==> ',chaine)
    let nature_filter='[["type_de_courrier" , "=","'+chaine+'"]]'
    this.userCourrierService.getAllowedNatureCourrier(nature_filter).then( res =>{
      //console.log(res.data)
      this.natures = res.data
    })
  }

  AddNewCourrier(dossier: NgForm){
    this.ngxService.start();
    let init_step =''
    // console.log('Form ===> ', dossier.value)
     init_step= this.getInitialStepOfDossier()

    if(dossier.value.reference ==''){
      dossier.value.reference='Non'
    }
    if (this.prevenusList.length == 0) {
     let prevenu = {

        addresse_complete_du_poursuivis: dossier.value['addresse_complete_du_poursuivis'],
        date_de_naissance_du_prévenu: dossier.value['date_de_naissance_du_prévenu'],
        lieu_de_naissance_du_prévenu: dossier.value['lieu_de_naissance_du_prévenu'],
        nom_complet_de_la_mere_du_prévenu: dossier.value['nom_complet_de_la_mere_du_prévenu'],
        nom_complet_du_pere_du_prévenu: dossier.value['nom_complet_du_pere_du_prévenu'],
        nom_complet_du_poursuivis: dossier.value['nom_complet_du_poursuivis'],
        numéro_didentification_du_poursuivis: dossier.value['numéro_didentification_du_poursuivis'],
        type_identification_du_poursuivis: dossier.value['type_identification_du_poursuivis'],
     }
     this.prevenusList.push(prevenu);
    }

    dossier.value["registre"]= 'Courrier Arrivé'
    dossier.value["docstatus"]= 0

    dossier.value["pieces_jointe_au_courrier"]= this.InternalPieces

    let pieces_jointe_au_dossier =[]
    for (let piece of this.InternalPieces){
      let body =  {
          "nom_du_fichier": piece.nom_du_fichier,
          "fichier": piece.fichier,
          "parentfield": "pieces_jointe_au_dossier",
          "parenttype": "Dossier",
          "doctype": "Fichiers joins a un dossier"
        }
      pieces_jointe_au_dossier.push(body)
    }

    dossier.value["pieces_jointe_au_dossier"]= pieces_jointe_au_dossier
    dossier.value['is_multiple_prevenu'] = this.is_multiple_prevenu
    dossier.value['association_de_prevenu'] = this.prevenusList
    if(this.my_tribunal !=null){
      dossier.value['tribunal'] = this.my_tribunal.name
    }

    console.log('Form ===> ', dossier.value)


    this.dossierService.nouveauDossier(dossier.value).then( (res:any) => {
      //console.log('REsult ---> ', res)
      this.dossierService.updateSatate(res.data.name,init_step).then(ress => {
        
        this.openModalSuccessDialog('Dossier enregisté avec success')
        this.closeWithMessage("success")
          this.ngxService.stop();
      },
        error =>{
          this.alerte.errorNotification('Mise a jour du dossier','Impossible de mettre a jour le dossier')
          console.log('Error On update==> ',error)
          this.ngxService.stop();
        })

      let update_courrier_infor ={
        "numero_dossier":res.data.name,
        "status_du_dossier":init_step
      }

      this.userCourrierService.updateCourrier(this.data.name,update_courrier_infor).then(res=>{
        console.log('Success ==> ',res)
       
      },error=>{
        console.log('Error On Update courrier => ',error)
      })
    },
      error =>{
        this.alerte.errorNotification('Creation de dossier','Impossible de creer le dossier')
        console.log('Error ==> ',error)
        this.ngxService.stop();
      })


  }

  closeModal(){
    this.bsModalRef.hide()
  }


  openModalAddPrevenu() {
    const config: ModalOptions = {
      initialState: {
        type: 'dossier',
        parentModal : null
      }
    }

    // Ouvrir le modal
    let modalRef = this.modalService.show(AddAssociationPrevenuComponent, Object.assign({}, config, { class: 'modal-lg ' }));

    modalRef.content.newPrevenuAdded.subscribe((newPrevenu: any) => {
      console.log('Nouveau prévenu ajouté :', newPrevenu);
      this.prevenusList.push(newPrevenu);
      console.log('Nouveau prévenu ajouté==> :', this.prevenusList);
    });
  }

  openModalDetailsPrevenu(prevenu:any) {
    const config: ModalOptions = {
      initialState: {
        url:URL,
        data: prevenu,
        parentModal : null
      }
    }

    // Ouvrir le modal
    let modalRef = this.modalService.show(DetailsPrevenuComponent, Object.assign({}, config, { class: 'modal-lg ' }));

    modalRef.content.newPrevenuAdded.subscribe((newPrevenu: any) => {

      let existingPrevenuIndex = this.prevenusList.find(p => p === prevenu);
      if (existingPrevenuIndex != null) {
        existingPrevenuIndex = newPrevenu;
      }

      console.log('findeddd :', existingPrevenuIndex);
      // this.prevenusList.push(newPrevenu);

    });
  }
  deletePrevenu(prevenu:any){
    let existingPrevenuIndex = this.prevenusList.find(p => p === prevenu);
    this.prevenusList.splice(existingPrevenuIndex, 1)

  }
  openModalVisualPiece(data: any) {
    const URL=MyCONST.API_URL+data.fichier
    const config: ModalOptions = {
      initialState: {
        data: 'https://petapixel.com/assets/uploads/2022/06/what-is-a-jpeg-featured.jpg',
        url:URL,
        parentModal : null
      }
    }

    // Ouvrir le modal
    const modalRef = this.modalService.show(VisualizePieceComponent, Object.assign({}, config, { class: 'modal-lg ' }));

    // Mettre à jour l'option initialState avec le modalRef
  }


  ngOnInit() {

    this.doAction()
    this.is_multiple_prevenu = 0;
    //console.log('This.data -----> ', this.data)
    try {
      this.my_tribunal= JSON.parse(localStorage.getItem('my_tribunal'))
    }catch (e) {
      this.my_tribunal =null
    }
    this.userCourrierService.getAllowedClassification().then( res =>{
      //console.log(res.data)
      this.classifications = res.data
    })
    this.userCourrierService.getAllowedNatureCourrier().then( res =>{
      console.log(res.data)
      this.natures = res.data
    })

    this.customservice.getIdentificationType().then(res=>{
      this.identification_type = res.data
      //console.log('identification_type ==> ',this.identification_type)
      //console.log('identification_type ==> ',res)
    })
    //console.log('this.data ==> ',this.data)
    var self = this;
    $(document).ready(function(){
      console.log('dddddd rrreeaady');

      self.initializeStepper();
      console.log('jjbjjn');
        // Ajouter une nouvelle ligne au tableau
        $('#addRowBtn').on('click', function() {

        });

        // Gérer la suppression d'une ligne
        $('#myTable').on('click', '.delete-row', function() {
          $(this).closest('tr').remove(); // Supprimer la ligne parente (tr) du clic sur l'icône poubelle (delete-row)
        });



    })

    // table reglements

  }
}
